const getCdnUrlWithImagesPart = (cdnUrl: string): string =>
  new URL('/images/', cdnUrl).toString()

// remove existing size specification from the url
const getNormalizeFileName = (fileName: string, cdnUrlLength: number): string =>
  fileName.slice(cdnUrlLength).replace(/^\d+x\d+\//u, '')

const getImageCdnUrlForSpecificWidth = (
  cdnUrl: string,
  src: string,
  width: number,
): string => {
  if (!src.startsWith(cdnUrl)) {
    return src
  }

  const cdnUrlWitImagesPart = getCdnUrlWithImagesPart(cdnUrl)
  const normalizeFileName = getNormalizeFileName(
    src,
    cdnUrlWitImagesPart.length,
  )
  const urlWithSize = new URL(`${width}x0/`, cdnUrlWitImagesPart).toString()

  const urlWithSizeAndFileName = new URL(
    normalizeFileName,
    urlWithSize,
  ).toString()

  return urlWithSizeAndFileName.toString()
}

export const getImageUrlWithWebpExtension = (url: string): string =>
  url.replace(/\.[^./]+$/u, '.webp')

export const imageAccessorGetImageSourceSizes = ({
  cdnUrl,
  setExtensionToWebp = false,
  sizes,
  src,
}: {
  cdnUrl: string
  setExtensionToWebp?: boolean
  sizes: [number, number][]
  src: string
}): string =>
  sizes
    .map(([, size]) => {
      const cdnUrlWithSize = getImageCdnUrlForSpecificWidth(cdnUrl, src, size)

      return `${
        setExtensionToWebp
          ? getImageUrlWithWebpExtension(cdnUrlWithSize)
          : cdnUrlWithSize
      } ${size}w`
    })
    .join(', ')

export const imageAccessorGetImageSizes = (
  imageSizes: number[][],
  smallestImageSize: number,
): string => {
  return [
    ...imageSizes.map(
      ([viewportWidth, imageWidth]) =>
        `(min-width: ${viewportWidth}px) ${imageWidth}px`,
    ),
    `${smallestImageSize}px`,
  ].join(',')
}

export const imageAccessorIsImageHostedOnCdn = (
  imageUrl: string,
  cdnUrl: string,
): boolean => imageUrl.startsWith(cdnUrl)
