'use client'

import {
  type ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useExperimentalAddStaticFileHost } from '../../experiments/new-cdn-experiment/useExperimentalAddStaticFileHost'
import { useGlobalConfigContext } from '../../global-config'
import { urlIsValid } from '../../url-handling'
import { IMAGE_PRODUCT_FALLBACK_SRC } from '../IMAGE_PRODUCT_FALLBACK_SRC'
import {
  imageAccessorGetImageSizes,
  imageAccessorGetImageSourceSizes,
} from '../imageAccessor'

type PictureProps = ComponentPropsWithoutRef<'picture'> & {
  alt: string
  imageSizes: [number, number][]
  lazy?: boolean
  smallestImageSize: number
  src: string
}

export const Picture = forwardRef<HTMLPictureElement, PictureProps>(
  function Picture(
    {
      alt,
      imageSizes,
      lazy = false,
      smallestImageSize,
      src,
      ...restProps
    }: PictureProps,
    ref,
  ) {
    const { cdn } = useGlobalConfigContext()
    const [hasError, setHasError] = useState(!urlIsValid(src))
    const imgRef = useRef<HTMLImageElement>(null)
    const { addStaticCdnHostToUrl } = useExperimentalAddStaticFileHost()

    const computedImageSizes = useMemo(
      () =>
        hasError
          ? undefined
          : imageAccessorGetImageSizes(imageSizes, smallestImageSize),
      [hasError, imageSizes, smallestImageSize],
    )

    const srcSetForWebp = useMemo(
      () =>
        hasError
          ? undefined
          : imageAccessorGetImageSourceSizes({
              cdnUrl: cdn.url,
              setExtensionToWebp: true,
              sizes: imageSizes,
              src,
            }),
      [hasError, cdn, imageSizes, src],
    )

    const srcSetForDefault = useMemo(
      () =>
        hasError
          ? undefined
          : imageAccessorGetImageSourceSizes({
              cdnUrl: cdn.url,
              sizes: imageSizes,
              src,
            }),
      [hasError, cdn, imageSizes, src],
    )

    const handleOnError = (): void => {
      setHasError(true)
    }

    /**
     * There are race conditions between image loading and hydration. If the browser
     * throws any errors before the hydration is run, those errors are lost, and we
     * need to programmatically determine that they happened.
     *
     * If the browser sets the "complete" flag to "true" but there isn't any image
     * loaded ("naturalHeight" is 0), we can assume that there was an error.
     *
     *
     * See https://github.com/facebook/react/issues/15446 for additional info
     */
    useEffect(() => {
      if (
        !hasError &&
        imgRef.current?.complete &&
        imgRef.current.naturalHeight === 0
      ) {
        setHasError(true)
      }
    }, [hasError])

    return (
      <picture ref={ref} {...restProps}>
        <source
          sizes={computedImageSizes}
          srcSet={srcSetForWebp}
          type="image/webp"
        />
        <img
          alt={alt}
          className="size-full object-contain"
          loading={lazy ? 'lazy' : 'eager'}
          onError={handleOnError}
          ref={imgRef}
          sizes={computedImageSizes}
          src={
            hasError ? addStaticCdnHostToUrl(IMAGE_PRODUCT_FALLBACK_SRC) : src
          }
          srcSet={srcSetForDefault}
        />
      </picture>
    )
  },
)
