import { type ExperimentType } from '../experiments/model/Experiment.types'

import { AD_SERVER_EXPERIMENTS_PREFIX } from './adServerConst'

export const adServerGetExperiments = (
  experiments: ExperimentType[],
): ExperimentType[] =>
  experiments.filter(({ isEnabled, name }) => {
    if (!isEnabled) {
      return false
    }

    return name.startsWith(AD_SERVER_EXPERIMENTS_PREFIX)
  })
