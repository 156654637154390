'use client'

import { type FC, useEffect } from 'react'

import { useExperimentsContext } from '../../../experiments/components/ExperimentsContext'
import { adServerGetExperiments } from '../../adServerGetExperiments'

export const AdServerExperimentActivation: FC = () => {
  const { addExperimentKeysForActivation, experiments } =
    useExperimentsContext()

  useEffect(() => {
    const featureKeys = adServerGetExperiments(experiments).map(
      (experiment) => experiment.name,
    )

    if (featureKeys.length > 0) {
      addExperimentKeysForActivation(featureKeys)
    }
  }, [addExperimentKeysForActivation, experiments])

  return null
}
